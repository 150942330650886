import * as React from "react";
import * as styles from "./terms-conditions.module.css";

import { FunctionComponent, useCallback, useState } from "react";

import Layout from '../components/Base/Layout';
import { SEO } from "../components/Base/Seo";

const TermsConditions: FunctionComponent = () => {


  return (
    <Layout>
      <div className={styles.termsConditions}>
        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
            <div className={styles.title}>
              <b className={styles.display}>Terms and Conditions</b>
            </div>
            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>Welcome to The Simply Express!</div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  These terms and conditions outline the rules and regulations
                  for the use of The Simply Express's Website -
                  https://simplyexpress.net/.
                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  By accessing this website, we assume you accept these terms
                  and conditions. Do not continue to use The Simply Express if you
                  do not agree to take all of the terms and conditions stated on
                  this page.
                </div>
              </div>
            </div>
            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>Cookies</div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We employ the use of cookies to personalize your online
                  experience with us. By accessing The Simply Express, you agreed to
                  use cookies in agreement with The Simply Express's Privacy Policy.
                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Cookies are text files placed on your hard disk by a web page
                  server. They are used to run programs or deliver viruses to
                  your device. Cookies are assigned to you and only read by a
                  server in the domain that issued the cookie to your computer.
                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Most interactive websites use cookies to let us retrieve the
                  user’s details for each visit. Cookies are used by our website
                  to enable the functionality of certain areas to make it easier
                  for people visiting our website. Some of our
                  affiliate/advertising partners may also use cookies.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>License</div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Unless otherwise stated, The Simply Express and/or its licensors
                  own the intellectual property rights for all material on The
                  Simply Express. All intellectual property rights are reserved. You
                  may access this from The  Simply Express for your own personal use
                  subjected to restrictions set in these terms and conditions.
                </div>
              </div>
              <div className={styles.p6}>
                <div className={styles.caption}>
                  <p className={styles.youMustNot}>You must not:</p>
                  <p className={styles.youMustNot}>&nbsp;</p>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      Reproduce and republish materials from The  Simply Express
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Sell sub-license material from The  Simply Express
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Duplicate or copy material from The Simply Express
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Redistribute content from The Simply Express
                    </li>
                  </ul>
                  <p className={styles.youMustNot}>&nbsp;</p>
                  <p className={styles.thisAgreementShall}>
                    This Agreement shall begin on the date hereof.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Parts of this website offer an opportunity for users to post
                  and exchange opinions and information in certain areas of the
                  website. The  Simply Express does not filter, edit, publish or
                  review Comments prior to their presence on the website.
                  Comments do not reflect the views and opinions of The Simply Express,
                  its agents and/or affiliates. Comments reflect the
                  views and opinions of the person who post their views and
                  opinions. To the extent permitted by applicable laws, The Simply Express
                  shall not be liable for the Comments or for any
                  liability, damages or expenses caused and/or suffered as a
                  result of any use of and/or posting of and/or appearance of
                  the Comments on this website.
                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  The Simply Express reserves the right to monitor all Comments and
                  to remove any Comments which can be considered inappropriate,
                  offensive or causes breach of these Terms and Conditions.
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  You warrant and represent that:
                </div>
              </div>
              <div className={styles.p6}>
                <div className={styles.caption}>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      You are entitled to post the Comments on our website and
                      have all necessary licenses and consents to do so;
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      The Comments do not invade any intellectual property
                      right, including without limitation copyright, patent or
                      trademark of any third party;
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      The Comments do not contain any defamatory, libelous,
                      offensive, indecent or otherwise unlawful material which
                      is an invasion of privacy
                    </li>
                    <li>
                      The Comments will not be used to solicit or promote
                      business or custom or present commercial activities or
                      unlawful activity.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  You hereby grant The  Simply Express a non-exclusive license to
                  use, reproduce, edit and authorize others to use, reproduce
                  and edit any of your Comments in any and all forms, formats or
                  media.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>Hyperlinking to our Content</div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  The following organizations may link to our website without
                  prior written approval:
                </div>
              </div>
            </div>
            <div className={styles.textbody7}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      Government agencies
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Search engines
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      News organizations
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Online directory distributors may link to our website in
                      the same manner as they hyperlink to the Websites of other
                      listed businesses
                    </li>
                    <li>
                      System wide Accredited Businesses except soliciting
                      non-profit organizations, charity shopping malls, and
                      charity fundraising groups which may not hyperlink to our
                      Web site.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  These organizations may link to our home page, to publications
                  or to other Website information so long as the link: (a) is
                  not in any way deceptive; (b) does not falsely imply
                  sponsorship, endorsement or approval of the linking party and
                  its products and/or services; and (c) fits within the context
                  of the linking party’s site.
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We may consider and approve other link requests from the
                  following types of organizations:
                </div>
              </div>
            </div>
            <div className={styles.textbody7}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      commonly-known consumer and/or business information
                      sources
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      dot.com community sites
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      associations or other groups representing charities
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      online directory distributors
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      internet portals
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      accounting, law and consulting firms
                    </li>
                    <li>educational institutions and trade associations</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We will approve link requests from these organizations if we
                  decide that: (a) the link would not make us look unfavorably
                  to ourselves or to our accredited businesses; (b) the
                  organization does not have any negative records with us; (c)
                  the benefit to us from the visibility of the hyperlink
                  compensates the absence of The  Simply Express; and (d) the link is
                  in the context of general resource information.
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  These organizations may link to our home page so long as the
                  link: (a) is not in any way deceptive; (b) does not falsely
                  imply sponsorship, endorsement or approval of the linking
                  party and its products or services; and (c) fits within the
                  context of the linking party’s site.
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  If you are one of the organizations listed in paragraph 2
                  above and are interested in linking to our website, you must
                  inform us by sending an e-mail to The Simply Express. Please
                  include your name, your organization name, contact information
                  as well as the URL of your site, a list of any URLs from which
                  you intend to link to our website, and a list of the URLs on
                  our site to which you would like to link. Wait 2-3 weeks for a
                  response.
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Approved organizations may hyperlink to our website as
                  follows:
                </div>
              </div>
            </div>
            <div className={styles.textbody7}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      By use of our corporate name
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      By use of the uniform resource locator being linked
                    </li>
                    <li>
                      By use of any other description of our website being
                      linked to that makes sense within the context and format
                      of content on the linking party’s site
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  No use of The Simply Express's logo or other artwork will be
                  allowed for linking absent a trademark license agreement.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>Content Liability</div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <a className={styles.caption23}>
                  We shall not be held responsible for any content that appears
                  on your Website. You agree to protect and defend us against
                  all claims that is rising on your Website. No link(s) should
                  appear on any Website that may be interpreted as libelous,
                  obscene or criminal, or which infringes, otherwise violates,
                  or advocates the infringement or other violation of, any third
                  party rights.
                </a>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>Your Privacy</div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <span>{`Please read `}</span>
                  <b className={styles.privacyPolicy}>Privacy Policy.</b>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>
                Removal of links from our website
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  If you find any link on our website that is offensive for any
                  reason, you are free to contact and inform us any moment. We
                  will consider requests to remove links, but we are not
                  obligated to or so or to respond to you directly.
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We do not ensure that the information on this website is
                  correct, we do not warrant its completeness or accuracy; nor
                  do we promise to ensure that the website remains available or
                  that the material on the website is kept up to date.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>Disclaimer</div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  To the maximum extent permitted by applicable law, we exclude
                  all representations, warranties and conditions relating to our
                  website and the use of this website. Nothing in this
                  disclaimer will:
                </div>
              </div>
            </div>
            <div className={styles.textbody7}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      limit or exclude our or your liability for death or
                      personal injury
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      limit or exclude our or your liability for fraud or
                      fraudulent misrepresentation
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      limit any of our or your liabilities in any way that is
                      not permitted under applicable law
                    </li>
                    <li>
                      exclude any of our or your liabilities that may not be
                      excluded under applicable law
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  The limitations and prohibitions of liability set in this
                  Section and elsewhere in this disclaimer: (a) are subject to
                  the preceding paragraph; and (b) govern all liabilities
                  arising under the disclaimer, including liabilities arising in
                  contract, in tort and for breach of statutory duty.
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  As long as the website and the information and services on the
                  website are provided free of charge, we will not be liable for
                  any loss or damage of any nature.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default TermsConditions;

export const Head = () => (
  <SEO title="Simply Express | Terms&Conditions" description="Terms&Conditions page" />
)
